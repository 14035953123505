import { useState, useEffect, Fragment } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import FeatureInfoAccordion from './feature-info-accordion';
import useHighlightLayer from './hooks/use-highlight-layer';
import { getDataMultiple } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';
import { OL_ZINDEXES, ZONE_LAYERS } from '../../config';

const sxStyles = {
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        backgroundColor: '#ddd',
        border: 'none',
    },
    tableContainer: {
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        }
    },
};

// JSS styles for non-mui components
const classes = {
    fedBoundariesFeatureInfoContainer: {
        marginTop: '10px',
    },
};

const dateFormat = {
    dateStyle: "medium",
    timeStyle: "long",
    timeZone: "UTC"
};

//memoized selector caches forecast types between renders
const selectZoneTypes = createSelector([getMapClickInfo, (state, olLayerState) => olLayerState], (info, olLayerState) => {
    // Get all unique zone types
    const data = info["federal_agency_boundaries"].data;
    const zoneTypes = new Set();
    for (const layer in data) {
        if (data[layer].features) {
            if (data[layer].features.length > 0 && olLayerState[layer].on) {
                zoneTypes.add(layer);
            }
        }
    }

    return zoneTypes;
});
/**
* Component for rendering results from getFeatureInfo requests
*
* @prop (obj) map - OL map object used for drawing feature info highlights
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
**/
export default function FederalBoundariesFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["federal_agency_boundaries"].data;
    const clickCoords = useSelector(getMapClickInfo)["federal_agency_boundaries"].coords;
    const zoneTypes = useSelector(state => selectZoneTypes(state, props.olLayerState));
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const [productsOpen, setProductsOpen] = useState({});

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.federal_boundaries_highlight_layer);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["federal_agency_boundaries"].urls) return;

        const fetchData = async () => {
            getDataMultiple(mapClickURLs["federal_agency_boundaries"].urls)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"federal_agency_boundaries" : {"data" : data, "coords" : mapClickURLs["federal_agency_boundaries"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        setProductsOpen({});

        if(clickCoords.x !== mapClickURLs["federal_agency_boundaries"].coords.x || clickCoords.y !== mapClickURLs["federal_agency_boundaries"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"federal_agency_boundaries" : {"data" : {features: []}, "coords" : mapClickURLs["federal_agency_boundaries"].coords}}));
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    useEffect(() => {
        let newProductsOpen = {};
        for(const type in zoneTypes) {
            newProductsOpen = Object.assign(newProductsOpen, {[type] : false});
        }
        setProductsOpen(newProductsOpen);
    }, [zoneTypes]);

    useEffect(() => {
        // Handle drawing of GeoJSON features returned by getFeatureInfo
        clearHighlight();
        if (Object.keys(clickData).length > 0) {
            for (const layer in clickData) {
                if (clickData && clickData[layer] && clickData[layer].features
                    && layer !== 'tc_ww_breakpoints' && layer !== 'ss_ww_communication_points') {
                    for (const feature of clickData[layer].features) {
                        if(productsOpen[layer] && productsOpen[layer] === true) {
                            drawFeatureHighlight(feature);
                        }
                    }
                }
            }
        }
    }, [productsOpen, clearHighlight, clickData, drawFeatureHighlight]);

    //if not all feature service requests have responded
    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    //if no zone features at click location
    if (Object.keys(clickData).length > 0 && zoneTypes.size === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location.</Typography>
            </div>
        );
    }

    let content = [...zoneTypes].map((zoneType,index) => {
        let status = true;
        if(!productsOpen[zoneType] || productsOpen[zoneType] === false) {
            status = false;
        }
        return (
            <FeatureInfoAccordion key={index}
                styleOverride={{borderLeft : "10px solid " + ZONE_LAYERS[zoneType].color}}
                featureName={ZONE_LAYERS[zoneType].label}
                expanded={status}
                setFeatureIsOn={() => {setProductsOpen((prevState) => {return({...prevState, [zoneType]: !status})})}}
            >
                <div style={{padding: '5px 0px 10px 0px'}}>
                    <TableContainer sx={sxStyles.tableContainer}>
                        <Table size="small">
                {
                    clickData[zoneType].features.map((feature, index) => {
                        return(
                                    <TableBody key={index}>
                                        {feature.properties.wfo ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO ID:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.wfo}</TableCell>
                                            </TableRow>
                                            : feature.properties.site_id ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>RFC ID:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.site_id}</TableCell>
                                            </TableRow>
                                            : feature.properties.name && (zoneType === "tc_ww_breakpoints" || zoneType === "ss_ww_communication_points") ?
                                            <TableRow sx={sxStyles.tableHeader}>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Name:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.name}</TableCell>
                                            </TableRow>
                                            : <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Name:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.name}</TableCell>
                                            </TableRow>
                                        }
                                        {feature.properties.region ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Region:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.region}</TableCell>
                                            </TableRow>
                                            : feature.properties.basin_id ?
                                            <Fragment><TableRow>
                                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>RFC Abbreviation:</TableCell>
                                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.basin_id}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Basin Name:</TableCell>
                                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.rfc_name}</TableCell>
                                                </TableRow>
                                            </Fragment>
                                            : null
                                        }
                                        {feature.properties.rfc_city ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.rfc_city}</TableCell>
                                            </TableRow>
                                            : feature.properties.city ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO Name:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.city}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                        {feature.properties.st ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>State:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.st}</TableCell>
                                            </TableRow>
                                            : (zoneType !== "tc_ww_breakpoints" && zoneType !== "ss_ww_communication_points") && feature.properties.state ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>State:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.state}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                        {(zoneType === "tc_ww_breakpoints" || zoneType === "ss_ww_communication_points") && feature.properties.state && feature.properties.country ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.state}, {feature.properties.country}</TableCell>
                                            </TableRow>
                                            : (zoneType === "tc_ww_breakpoints" || zoneType === "ss_ww_communication_points") && feature.properties.state ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.state}</TableCell>
                                            </TableRow>
                                            : (zoneType === "tc_ww_breakpoints" || zoneType === "ss_ww_communication_points") && feature.properties.country ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.country}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                        {feature.properties.lat && feature.properties.lon ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Coordinates:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.lat}, {feature.properties.lon}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                        {feature.properties.latitude && feature.properties.longitude ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Coordinates:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.latitude}, {feature.properties.longitude}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                        {(zoneType !== "tc_ww_breakpoints" && zoneType !== "ss_ww_communication_points") && feature.properties.country ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Country:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.country}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                        {feature.properties.idp_filedate ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Service Date:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(feature.properties.idp_filedate)}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                    </TableBody>
                        );
                    })
                }
                        </Table>
                    </TableContainer>
                </div>
            </FeatureInfoAccordion>
        )
    });

    return(
        <div style={classes.fedBoundariesFeatureInfoContainer}>
            {content}
        </div>
    );
}
