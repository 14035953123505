import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flushSync } from 'react-dom';
import DOMPurify from 'dompurify';

import { ScaleLine, Zoom, Attribution } from 'ol/control.js';
import Map from 'ol/Map';
import View from 'ol/View';
import * as olExtent from 'ol/extent';
import LayerGroup from 'ol/layer/Group';
import { toLonLat, fromLonLat } from 'ol/proj';
import Link from 'ol/interaction/Link.js';
import Geolocation from 'ol/Geolocation.js';
import olms from 'ol-mapbox-style';

import NCHeader from './components/header.js';
import NCFooter from './components/footer.js';
import OLMap from './components/map/map.js';
import MapMenus from './components/menu/map-menus.js';
import MapClickPopup from './components/map-click-popup/map-click-popup.js';

import WMSCapabilitiesHandler from './utilities/capabilities-handler-wms.js';
import DimensionControl from './utilities/dimension-state-controller.js';
import { BookmarkToggleButton, ResetPositionButton, GeolocationButton } from './components/ui-controls/map-buttons.js';
import OLMapContext from './components/map/map-context.js';
import { mapClicked, mapClickURLsUpdated, mapClickFeaturesUpdated, getMapClickCoords, getMapClickEvent, surfaceCurrentsOn } from '../features/mapClickSlice.js';
import { LAYERS, DEFAULT_MAP_POSITIONS, DEFAULT_BASEMAP, BASEMAPS, ZONE_LAYERS, ZONE_FORECAST_LAYERS, BREAKPOINT_VALUES, CONVECTIVE_LAYERS, OL_ZINDEXES, OFS_SFC_CURRENTS_INFO } from './config.js';

const MRMS_QPE_RASTER_FUNCS_TO_SUBSETS = {
    "rft_1hr": "mrms_01h",
    "rft_3hr": "mrms_03h",
    "rft_6hr": "mrms_06h",
    "rft_12hr": "mrms_12h",
    "rft_24hr": "mrms_24h",
    "rft_48hr": "mrms_48h",
    "rft_72hr": "mrms_72h",
}

// these product lists are used in the bookmark URL validation process
export const MULTIPLE_STYLE_PRODUCTS = [
    "nbs",
    "ndfd",
    "s100",
    "tropical_cyclones"
];

const MULTIPLE_LAYER_PARAM_PRODUCTS = [
    "mrms_qpe",
    "tropical_cyclones"
];

const DEFAULT_CONFIG_PRODUCTS = [
    "mrms",
    "wwa",
    "stofs",
    "ltng_den"
]

// Maps NWS' QPE Service's raster functions to the idp_subsets which must match in /Identify requests
// Note that there are 4 regions co, hi, ak, pr which MUST be appended to the subset value ie. mrms_01h_co
// in order for the requests to work

const ZINDEXES = { // for viewer React components (not OL Layer zIndexes)
        'ol_map': 0,
        'header': 50,
        'layer_menu': 200,
        'animation_control': 500,
        'footer': 55,
        'map_click_popup': 999
};
// animation speed clickaway menu has zIndex: '1100' hardcoded

const NCMapViewerApp = () => {
    // all "global" state variables
    // complex objects have initialization functions
    const [bookmark, setBookmark] = useState(getBookmark()); // bool - initializer checks if URL has parameters
    const [olLayerState, setOlLayerState] = useState(initialLayerState()); // obj - see initializer
    const [productToggles, setProductToggles] = useState(initialProductToggles()); // obj - see initializer
    const [initializedCaps, setInitializedCaps] = useState(initialCaps()); // obj - see initializer
    const [styleInfo, setStyleInfo] = useState(initialStyleInfo()); // obj - see initializer
    const [layerOpacities, setLayerOpacities] = useState(initialLayerOpacities()); // obj - Store the opacity of each layer
    const [mapClickPopupOn, setMapClickPopupOn] = useState(false); // bool
    const [mapClickTime, setMapClickTime] = useState(null); // timestamp - Store selectedTime for last map click
    const [selectedTime, setSelectedTime] = useState(null); // timestamp - Store current time if time slider is on, else null (int, epoch time in milliseconds)
    // Store all known time values of animation data organized by toggle-able products
    // mapped to objects that map layer names to their list of time values from capabilities
    // (this is updated by only by "capabilitiesUpdated" event and consumed only by time-control)
    const [timeValues, setTimeValues] = useState({});
    const [customLayerInfo, setCustomLayerInfo] = useState({}); // obj - Store all info on custom layers that have been added by user
    const [layerMenuOn, setLayerMenuOn] = useState(false); // bool - These allow menu panes to be mutually exclusive
    const [legendMenuOn, setLegendMenuOn] = useState(false); // bool
    const [infoMenuOn, setInfoMenuOn] = useState(false); // bool
    //const [popupPrevMenuState, setPopupPrevMenuState] = useState(null);
    const [prevBasemap, setPrevBasemap] = useState(getInitialBasemap());
    const [selectedBasemap, setSelectedBasemap] = useState(getInitialBasemap());
    const [refreshLayers, setRefreshLayers] = useState(false); // bool - triggers useEffect
    const [productInfo, setProductInfo] = useState(initialProductInfo()); // obj - see initializer
    const [refreshLocation, setRefreshLocation] = useState(false); // bool - triggers useEffect
    const [location, setLocation] = useState(); // OL Coordinate of user location
    const [geolocationError, setGeolocationError] = useState(); // OL GeolocationError event (if user does not give location permissions)
    // Instantiation of all persistent class objects needed for app
    const geolocation = useRef(new Geolocation()); // OL Geolocation - interacts with Geolocation API in browser
    const zoomControl = useRef(new Zoom()); // OL Zoom control
    const linkControl = useRef(new Link({animate: false, params: ['x', 'y', 'z']})); // OL Link which updates the map center coordinates and zoom level in URL
    const timeStateController = useRef(new DimensionControl(['time', 'dim_time_reference'])); // Time Dimension Controller
    const curBreakPoint = useRef(getBreakPoint(window.innerWidth)); // str - initial breakpoint
    const olMap = useRef(); // holds OL map once initialized by useEffect
	const mapElement = useRef(); // map reference
    const mapCenter = useRef(getMapCenter(curBreakPoint)); // array - [x, y] OL map center coordinates
    const mapZoom = useRef(getMapZoom(curBreakPoint)); // float - OL map zoom level
    const capHandlers = useRef(initialCapHandlers()); // WMS Capabilities Request Handlers
    // map click state selectors from Redux slice
    const mapClickCoords = useSelector(getMapClickCoords); // Stores coordinates of last map click {x: coordinate, y: coordinate}
    const mapClickEvent = useSelector(getMapClickEvent); // OL Event - stores most recent map click event, used to get the pixel and coordinates of the click location
    const dispatch = useDispatch(); // dispatches Redux reducers

    // map is set when application mounts
    // initializes map, removes zoom controls if necessary, applies OL geolocation, and sets initial surface currents layer state in Redux
    useEffect(() => {
        if(!mapElement.current || !curBreakPoint.current) return;
        const mapObj = initializeMap();
        olMap.current = mapObj;
        olMap.current.setTarget(mapElement.current);
        if(curBreakPoint.current === 'xs' || curBreakPoint.current === 'sm') {
            olMap.current.removeControl(zoomControl.current);
        }
        geolocation.current.setProjection(olMap.current.getView().getProjection());
        geolocation.current.on('error', function (error) {
            setGeolocationError(error);
        });
        geolocation.current.setTracking(true);
        if(olLayerState) {
            for(const sfcLayer of Object.keys(OFS_SFC_CURRENTS_INFO)) {
                if(olLayerState[sfcLayer].on) {
                    dispatch(surfaceCurrentsOn(sfcLayer));
                }
            }
        }
        return () => olMap.current.setTarget(undefined);
    }, []);

    useEffect(() => {
        // refresh interval for surface obs is being set when product toggles change if surface obs is turned on
        let obsInterval = null;
        if (productToggles["surface_obs"]) {
            obsInterval = setInterval(() => {
                const obsStationSource = LAYERS['surface_obs'].layers['station_obs_scale6'].sources['station_obs_scale6'].sourceObj.source;
                const cachedSource = LAYERS['surface_obs'].layers['cached_stations'].sources['cached_stations'].sourceObj.source;
                const staticSource = LAYERS['surface_obs'].layers['static_clusters'].sources['static_clusters'].sourceObj.source;
                cachedSource.clear();
                staticSource.refresh();
                obsStationSource.refresh();
            }, 3600000);
        }
        return () => clearInterval(obsInterval);
    }, [productToggles]);

    // Handles updates to URL if bookmarking is on
    // If bookmarking is toggled off, all parameters are removed from the URL
    useEffect(() => {
        let url = new URL(DOMPurify.sanitize(window.location.href));
        if(bookmark) {
            // adds basemap to URL, ex: https://nowcoast.noaa.gov?basemap=streets
            url.searchParams.set('basemap', selectedBasemap.split('/').slice(-1));
            // adds each product's parameters to URL, including opacity (op), layers (ly1, ly2, etc.), layer parameters (lp1), styles (st1), & sources (so1)
            // ex: ?&tropical_cyclones=on,op=40,ly1=tropical_cyclones,lp1=tropical_cyclone_track_forecast|tropical_cyclone_intensity_forecast,ly2=tropical_ss,lp2=tidalmask
            // &ndfd=on,ly1=ndfd,so1=significant_wave_height
            for(const [product, status] of Object.entries(productToggles)) {
                if(status) {
                    let layersOn = {};
                    // get opacity if not default
                    let layerOpacityParam = ',op=' + layerOpacities[product];
                    if(layerOpacities[product] === LAYERS[product].opacity) {
                        layerOpacityParam = '';
                    }
                    // get product layers if on
                    for(const layer in LAYERS[product].layers) {
                        if(olLayerState[layer].on) {
                            Object.assign(layersOn, {[layer] : olLayerState[layer]});
                        }
                    }
                    if(Object.keys(layersOn).length > 0 && !DEFAULT_CONFIG_PRODUCTS.includes(product)) {
                        let layerCount = 1;
                        let layerParamStrings = [];
                        for(const [layer, layerProps] of Object.entries(layersOn)) {
                            // get layer name
                            let layerParamString = "ly" + layerCount.toString() + "=" + layer;
                            // get layer source (if more than one)
                            if(layerProps.currentSource && Object.keys(LAYERS[product].layers[layer].sources).length > 1) {
                                layerParamString = layerParamString + ",so" + layerCount.toString() + "=" + layerProps.currentSource;
                            }
                            // get layer styles (if more than one, in MULTIPLE_STYLE_PRODUCTS)
                            if(MULTIPLE_STYLE_PRODUCTS.includes(product) && layerProps.stylesParam.length > 0) {
                                layerParamString = layerParamString + ",st" + layerCount.toString() + "=" + layerProps.stylesParam.join('|');
                            }
                            // get layer parameters (if more than one, in MULTIPLE_LAYER_PARAM_PRODUCTS)
                            if(MULTIPLE_LAYER_PARAM_PRODUCTS.includes(product) && layerProps.layersParam.length > 0) {
                                if(Array.isArray(layerProps.layersParam)) {
                                    // multiple styles at a time, currently just tropical_cyclones
                                    layerParamString = layerParamString + ",lp" + layerCount.toString() + "=" + layerProps.layersParam.join('|');
                                } else {
                                    if(LAYERS[product].layers[layer].sources[layerProps.currentSource].sourceObj.params_.LAYERS.includes(layerProps.layersParam)) {
                                        layerParamString = layerParamString + ",lp" + layerCount.toString() + "=" + layerProps.layersParam;
                                    }
                                }
                            }
                            layerParamStrings.push(layerParamString);
                            layerCount++;
                        }
                        url.searchParams.set(product, 'on' + layerOpacityParam + ',' + layerParamStrings.join(','));
                    } else {
                        // if product only has one setting (in DEFAULT_CONFIG_PRODUCTS)
                        // ex: ?wwa=on,op=100
                        url.searchParams.set(product, 'on' + layerOpacityParam);
                    }
                } else {
                    // if product has been turned off, remove from URL
                    if(url.searchParams.has(product)) {
                        url.searchParams.delete(product);
                    }
                }
            }
            // sets map center coordinates and zoom level
            url.searchParams.set('x', olMap.current.getView().getCenter()[0]);
            url.searchParams.set('y', olMap.current.getView().getCenter()[1]);
            url.searchParams.set('z', parseFloat(olMap.current.getView().getZoom().toFixed(5)));
        } else {
            // if bookmarking is turned off, remove all parameters from URL
            if(Array.from(url.searchParams.keys()).length > 0) {
                for(const key of [...url.searchParams.keys()]) {
                    url.searchParams.delete(key);
                }
            }
        }
        window.history.replaceState(null, null, url);
    }, [bookmark, productToggles, selectedBasemap, olLayerState, layerOpacities]);

    // Adds link between map and URL when bookmark button is toggled on
    // Removes when toggled off
    // Allows coordinates and zoom level to be displayed in URL and bookmarked
    useEffect(() => {
        if(bookmark) {
            olMap.current.addInteraction(linkControl.current);
        } else {
            olMap.current.removeInteraction(linkControl.current);
        }
    }, [bookmark]);

    const toggleBookmarking = useCallback(() => {
        const update = !bookmark;
        setBookmark(update);
    }, [bookmark]);

    // callbacks for manipulating state

    // Update some or all of state object olLayerState
    // update param must be of form {layerName:{on: t/f, sources: [list of sourceNames]}}
    // Optional Usage: Provide key arg (valid string matching an existing key in obj (ie. a layer name)
    // and pass an update that is a part of the object pointed to by the key. ex. {'on': true}
    // This allows us to update individual aspects of a layer without needing to have
    // the whole object on hand to spread in
    const updateOlLayerState = useCallback((update, key=null) => {
        let newVals = update;
        let newOlLayerState = Object.assign({}, olLayerState);
        if(key) {
            Object.assign(newOlLayerState[key], newVals);
        } else {
            Object.assign(newOlLayerState, newVals);
        }
        setOlLayerState(newOlLayerState);
    }, [olLayerState]);

    // this callback validates the style parameters in olLayerState
    // if invalid styles were introduced in the URL, this function will set the style to the default
    // called in map.js when capabilities are initialized and gets styles directly from WMS
    const validateOlStyle = useCallback((styleProps) => {
        const product = Object.keys(styleProps)[0];
        let validOlLayerState = {...olLayerState};
        let styleInvalid = false;
        for(const layer in LAYERS[product].layers) {
            let verifiedStyles = [];
            if(olLayerState[layer].stylesParam && olLayerState[layer].stylesParam.length > 0) {
                for(const layerStateStyle of olLayerState[layer].stylesParam) {
                    if(styleProps[product][layer].find(lyr => lyr.name === layerStateStyle)) {
                        verifiedStyles.push(layerStateStyle);
                    }
                }
                if(verifiedStyles.length === 0 || verifiedStyles.length !== olLayerState[layer].stylesParam.length) {
                    styleInvalid = true;
                    verifiedStyles = olLayerState[layer].defaultStyles;
                    let curDetails = {
                        'on': olLayerState[layer].on,
                        'layersParam': olLayerState[layer].layersParam,
                        'stylesParam': verifiedStyles,
                        'currentSource': olLayerState[layer].currentSource,
                        'defaultStyles': olLayerState[layer].defaultStyles
                    }
                    Object.assign(validOlLayerState, {[layer]: curDetails});
                }
            }
        }
        if(styleInvalid) {
            setOlLayerState(validOlLayerState);
        }
    }, [olLayerState]);

    const updateProductToggles = useCallback((update, rm=false) => {
        let newProductToggles = { ...productToggles };
        if (rm) {
            newProductToggles = [...newProductToggles, delete newProductToggles[update]];
        } else {
            Object.assign(newProductToggles, update);
        }
        setProductToggles(newProductToggles);
    }, [productToggles]);

    const updateInitializedCaps = useCallback((update) => {
        let newInitializedCaps = Object.assign({}, initializedCaps);
        Object.assign(newInitializedCaps, update);
        setInitializedCaps(newInitializedCaps);
    }, [initializedCaps]);

    const updateTimeValues = useCallback((update) => {
        setTimeValues(Object.assign(timeValues, update));
    }, [timeValues]);

    const updateStyleInfo = useCallback((update) => {
        setStyleInfo(Object.assign(styleInfo, update));
    }, [styleInfo]);

    const updateLayerOpacities = useCallback((update) => {
        let newLayerOpacities = Object.assign({}, layerOpacities);
        Object.assign(newLayerOpacities, update);
        setLayerOpacities(newLayerOpacities);
    }, [layerOpacities]);

    const updateMapClickPopup = useCallback((update) => {
        setMapClickPopupOn(update);
    }, []);

    const updateSelectedTime = useCallback((update) => {
        setSelectedTime(update);
    }, []);

    const updateMapClickTime = useCallback((update) => {
        setMapClickTime(update);
    }, []);

    const updateLayers = useCallback((update) => {
        setRefreshLayers(update);
    }, []);

    const updateCustomLayerInfo = useCallback((update) => {
        let newCustomLayerInfo = Object.assign({}, customLayerInfo);
        Object.assign(newCustomLayerInfo, update);
        setCustomLayerInfo(newCustomLayerInfo);
    }, [customLayerInfo]);

    const updateProductInfo = useCallback((update) => {
        const product = Object.keys(update)[0];
        let mergedUpdate = update[product];
        mergedUpdate.keywords = [...new Set(productInfo[product].keywords.concat(update[product].keywords))];
        mergedUpdate = Object.assign(mergedUpdate, update[product]);
        setProductInfo(Object.assign(productInfo, {[product]: mergedUpdate}));
    }, [productInfo]);

    // map functions
    const setBasemap = useCallback((basemap, previousBasemap = null) => {
        if(previousBasemap) {
            setPrevBasemap(previousBasemap);
        } else {
            setPrevBasemap(basemap);
        }

        olMap.current.setLayerGroup(new LayerGroup());

        olms(olMap.current, getBasemapUrl(basemap));

        updateLayers(!refreshLayers);
        setSelectedBasemap(basemap);
    }, [refreshLayers, updateLayers]);

    const resetPosition = useCallback(() => {
        olMap.current.getView().setCenter(fromLonLat(DEFAULT_MAP_POSITIONS[curBreakPoint.current].center));
        olMap.current.getView().setZoom(DEFAULT_MAP_POSITIONS[curBreakPoint.current].zoom);
    }, []);

    const geolocate = useCallback(() => {
        if(geolocation.current.getPosition()) {
            setLocation(geolocation.current.getPosition());
        }
        setRefreshLocation(!refreshLocation);
    }, [refreshLocation]);

    function getBasemapUrl(basemap) {
        // Vector Tiles created from an openlayers Mapbox Style object
        // olms returns a promise which resolves when it completes
        // This low-level API does not create a source for the layer
        // https://github.com/openlayers/ol-mapbox-style
        const apiKey = process.env.REACT_APP_API_KEY
        const baseUrl = "https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles";
        const url = (name) => {
            if (name === "arcgis/imagery" || name === "arcgis/oceans") return `${baseUrl}/${name}?type=style&token=${apiKey}&language=en&worldview=unitedStatesOfAmerica`;
            return `${baseUrl}/${name}?type=style&token=${apiKey}&language=en`;
        }
        return url(basemap);
    };

    // Create callback function for removing the zoom control
    // Arg: showZoom (bool) - true to show, false to remove
    function showZoom(zoomOn) {
        if (!olMap.current) return;
        let controlExists = false;
        olMap.current.getControls().forEach((control) => {
            if (control instanceof Zoom) {
                controlExists = true;
            }
        });

        if (zoomOn && !controlExists) {
            olMap.current.addControl(zoomControl.current);
        }

        if (!zoomOn && controlExists) {
            olMap.current.removeControl(zoomControl.current);
        }
    };

    // creates map, sets basemap, and creates listeners
    // click listener for updating mapClickCoords and mapClickEvent in Redux state is set here
    function initializeMap() {
        const scaleLine = new ScaleLine();
        scaleLine.setUnits('us');
        const attribution = new Attribution({collapsible: true});
        const olMap = new Map({
            controls: [scaleLine, zoomControl.current, attribution],
            target: null,
            serverType: 'geoserver',
            maxTilesLoading: 10,
            layers: [],
            pixelRatio: 1,
            view: new View({enableRotation: false}),
        });

        olMap.getView().setCenter(mapCenter.current);
        olMap.getView().setZoom(mapZoom.current);
        if(bookmark) {
            olMap.addInteraction(linkControl.current);
        }
        // Set basemap layer once as part of map initialization
        olms(olMap, getBasemapUrl(getInitialBasemap()));

        // Register event handler for mapClicks/touches
        olMap.on('singleclick', (evt) => {
            var evtCoordinateX = evt.coordinate[0];
            var evtCoordinateY = evt.coordinate[1];
            var checkCoordinates = olExtent.containsXY(
                                   [-20037508.342789244, -20048966.1040146, 20037508.342789244, 20048966.104014594],
                                   evtCoordinateX, evtCoordinateY);

            //Normalize the longitude if outside the range
            if (!checkCoordinates) {

                const web_mercator_maxX = 20037508.342789244;

                while(evtCoordinateX < -web_mercator_maxX){
                  evtCoordinateX += 2 * web_mercator_maxX;
                }
                while (evtCoordinateX > web_mercator_maxX){
                  evtCoordinateX -= 2 * web_mercator_maxX;
                }
            }

            flushSync(() => {
                dispatch(mapClicked({x: evtCoordinateX, y: evtCoordinateY, event: evt}));
            });

            //for WFS, store features here in their own object in Redux
            let obsFeatures = [], COOPSFeatures = [];
            obsFeatures = olMap.forEachFeatureAtPixel(evt.pixel,
                function(feature) {
                    let features = [];
                    for(const newFeature of feature.get('features').splice(0, 20)) {
                        newFeature.setProperties({'layer': 'surface_obs'});
                        features.push(newFeature);
                    }
                    return features;}, {
                layerFilter: function(layer) {
                    return layer.getZIndex() === OL_ZINDEXES['surface_obs'] || layer.getZIndex() === OL_ZINDEXES['surface_obs_static']
                }
            });
            COOPSFeatures = olMap.forEachFeatureAtPixel(evt.pixel,
                function(feature) {
                    let features = [];
                    for(const newFeatures of feature.get('features').splice(0, 20)) {
                        const newFeature = newFeatures;
                        newFeature.setId(newFeature.getProperties()['Id']);
                        newFeature.setProperties({'layer': 'co_ops_stations'});
                        features.push(newFeature);
                    }
                    return features; }, {
                    layerFilter: function(layer) {
                    return layer.getZIndex() === OL_ZINDEXES['co_ops_stations']
                }
            });
            dispatch(mapClickFeaturesUpdated({"obsFeatures" : obsFeatures, "COOPSFeatures" : COOPSFeatures, "coords" : {x: evtCoordinateX, y: evtCoordinateY}}));
        });

        olMap.on('moveend', (evt) => {
            if (productToggles["surface_obs"]) {
                const obsStationSource = LAYERS['surface_obs'].layers['station_obs_scale6'].sources['station_obs_scale6'].sourceObj.source;
                obsStationSource.refresh();
            }
        });

        return olMap;
    }

    // Gather all URLs relevant to this map-click then turn on popup
    // NOTE: Formerly all click info was retrieved as soon as URLs were formed
    // Now click data is only retrieved if the user clicks a product's tab in the popup
    // This reduces unnecessary requests and initial load time as we don't need to wait for point fc every time
    // However, it means there is a little bit of load time each time a tab is first opened after a new map click
    // Click data requests are now sent in a useEffect in each product's feature info component
    const getInfoUrls = useCallback(() => {
        let newMapClickInfo = {};
        const viewResolution = olMap.current.getView().getResolution();
        for (let product in productToggles) {
            if (productToggles[product]) {
                for (let olLayerName in LAYERS[product].layers) {
                    const olSourceName = olLayerState[olLayerName].currentSource;
                    try {
                        let url = null;
                        // Do not call getFeatureInfoUrl from external WMS/WFS sources sources
                        if (olLayerName !== "cwa_boundaries" && olLayerName !=="river_forcast_centers"
                            && product !== "zone_forecasts" && product !== "convective_outlooks" && product !== "surface_obs") {
                            url = LAYERS[product].layers[olLayerName].sources[olSourceName].sourceObj.getFeatureInfoUrl(
                                [mapClickCoords.x, mapClickCoords.y],
                                viewResolution,
                                'EPSG:3857',
                                {'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': '50'}
                            );
                        }
                        let infoUpdate = {url: url, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                        if (product === "mrms_qpe") {
                            const xVal = mapClickCoords.x;
                            const yVal = mapClickCoords.y;
                            const rasterFunc = olLayerState[olLayerName].layersParam.split(':')[1];
                            const urlPart1 = "https://mapservices.weather.noaa.gov/raster/rest/services/obs/mrms_qpe/ImageServer/identify?f=json&geometryType=esriGeometryPoint&geometry=%7B%22spatialReference%22%3A%7B%22latestWkid%22%3A3857%2C%22wkid%22%3A102100%7D%2C%22x%22%3A";
                            const urlPart2 = "%2C%22y%22%3A";
                            const urlPart3 = "%7D&mosaicRule=%7B%22ascending%22%3Afalse%2C%22where%22%3A%22idp_subset%3D%27";
                            const urlPart4 = "%27%22%2C%22mosaicMethod%22%3A%22esriMosaicAttribute%22%2C%22mosaicOperation%22%3A%22MT_FIRST%22%2C%22sortField%22%3A%22StdTime%22%2C%22sortValue%22%3A%220%22%7D&renderingRules=%5B%7B%22rasterFunction%22%3A%22";
                            const urlPart5 = "%22%7D%5D&pixelSize=%7B%22spatialReference%22%3A%7B%22latestWkid%22%3A3857%2C%22wkid%22%3A102100%7D%2C%22x%22%3A4891.96981024998%2C%22y%22%3A4891.96981024998%7D&returnGeometry=false&returnCatalogItems=true&returnPixelValues=true&maxItemCount=1&processAsMultidimensional=false";

                            if (!newMapClickInfo[product]) { // If this is first region, then create object
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }

                            // Make one URL for each region: co, hi, ak, pr
                            const regions = ["co", "ak", "hi", "pr"];
                            regions.forEach((region) => {
                                const idpSubset = MRMS_QPE_RASTER_FUNCS_TO_SUBSETS[rasterFunc] + '_' + region;
                                const mrmsQpeUrl = urlPart1 + xVal + urlPart2 + yVal + urlPart3 + idpSubset + urlPart4 + rasterFunc + urlPart5;
                                newMapClickInfo[product].urls[region] = mrmsQpeUrl;
                            });
                        } else if (product === "tropical_cyclones") {
                            const cycloneInfoUrls = {};
                            if (!newMapClickInfo[product]) {
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }

                            if (olLayerName === "tropical_ss" && olLayerState[olLayerName].on) {
                                newMapClickInfo[product].urls = {[olLayerName]: url, ...newMapClickInfo[product].urls}
                            }

                            if (olLayerName !== "tropical_ss") { // Handle tropical_cyclones feature info url creation
                                // Build all required urls:
                                // We have a feature info url with individual layers in a comma separated string. The same layer list/string
                                // is present for QUERY_LAYERS and LAYERS parameters. Goal here is to recreate the same url
                                // for each layer in the list using only one layer name. The parse first breaks off the query portion
                                // of the url by splitting on ?. Then it parses each parameter in the query and builds all the urls. It
                                // should be able to handle the parameters in any order, it just requires that LAYERS and QUERY_LAYERS
                                // are present and have the same value

                                let [urlPath, urlQuery] = url.split('?');
                                let queryTokens = urlQuery.split('&'); // List of each GET Param in query
                                let otherParams = urlPath + "?"; // String holding params that come either before or between QUERY_LAYERS and LAYERS
                                let layers;
                                let queryLayersParamComplete = false;
                                let layersParamComplete = false;
                                let index = 0;

                                for (const token of queryTokens) {
                                    const ampersand = (index === 0) ? "" : "&"; //First token should not be preceded by &
                                    if (token.split("=")[0] === "LAYERS") {
                                        layers = token.slice(7);
                                        for (const layer of layers.split('%2C')) {
                                            if (!cycloneInfoUrls.hasOwnProperty(layer)) {cycloneInfoUrls[layer] = ""}
                                            cycloneInfoUrls[layer] = cycloneInfoUrls[layer] + otherParams + ampersand + "LAYERS=" + layer;
                                        }
                                        otherParams = "";
                                        layersParamComplete = true;
                                    } else if (token.split("=")[0] === "QUERY_LAYERS") {
                                        layers = token.slice(13);
                                        for (const layer of layers.split('%2C')) {
                                            if (!cycloneInfoUrls.hasOwnProperty(layer)) {cycloneInfoUrls[layer] = ""}
                                            cycloneInfoUrls[layer] = cycloneInfoUrls[layer] + otherParams + ampersand + "QUERY_LAYERS=" + layer;
                                        }
                                        otherParams = "";
                                        queryLayersParamComplete = true;
                                    // Next two cases cover what to do if the current token/param is not LAYERS or QUERY_LAYERS
                                    // We are concatenating the param to otherParams if we havnt seen both LAYERS or QUERY_LAYERS yet
                                    // If we have already handled both LAYERS and QUERY_LAYERS then we use final branch where
                                    // we just add the param onto the end of each url
                                    } else if (!queryLayersParamComplete || !layersParamComplete){
                                        otherParams = otherParams + ampersand + token;
                                    } else {
                                        for (const layer in cycloneInfoUrls) {
                                            cycloneInfoUrls[layer] = cycloneInfoUrls[layer] + ampersand + token;
                                        }
                                    }
                                    index += 1;
                                }
                                newMapClickInfo[product].urls = Object.assign(newMapClickInfo[product].urls, cycloneInfoUrls);
                            }
                        } else if (product === "ndfd" || product === "s111" || product === "sst" || product === "vlm" || product === "satellite") {
                            // NDFD has 5 OLlayers (one from each region) representing
                            // one product/productToggles key ('ndfd'). It needs to query all 5 layers for feature info
                            // so its mapClickInfo obj is structured like tropical_cyclones where it has
                            // 'urls' key which will map to an object that maps olLayerNames to their urls.
                            // The 'data' key will also map to an object that maps olLayerNames to the json data
                            // returned by getFeatureInfo
                            // NDFDFeatureInfo component will have to hardcode these keys (format: "ndfd_region" which matches
                            // OL layer keys used in config.js
                            if (!newMapClickInfo[product]) {
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }
                            newMapClickInfo[product].urls[olLayerName] = url;
                            // Adding key to data object before async requests are made. This guarantees that all keys will
                            // be in place which helps the ndfd-feature-info component know whether queries are done or not
                        } else if (product === "nbs") {
                            // Need one URL for bathy and one for tile scheme, but we only want tile scheme if it is toggled on
                            if (olLayerName === "bluetopo_tile_scheme" && !olLayerState.bluetopo_tile_scheme.on) continue;
                            if (!newMapClickInfo[product]) {
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }
                            if (url.includes('STYLES=nbs_elevation')) {
                                url = url.replace('STYLES=nbs_elevation', 'STYLES=source_institution')
                            }
                            if (url.includes('STYLES=nbs_uncertainty')) {
                                url = url.replace('STYLES=nbs_uncertainty', 'STYLES=source_institution')
                            }
                            newMapClickInfo[product].urls[olLayerName] = url;
                        } else if (product === "s100") {
                            // Need one URL for each s100 service
                            if (!newMapClickInfo[product]) {
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }
                            url = LAYERS[product].layers[olLayerName].sources[olSourceName].sourceObj.getFeatureInfoUrl(
                                [mapClickCoords.x, mapClickCoords.y],
                                viewResolution,
                                'EPSG:3857',
                                {'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': '1'}
                            );
                            newMapClickInfo[product].urls[olLayerName] = url;
                        } else if (product === "federal_agency_boundaries"){
                            // Manually create one URL for each federal boundary layer. Query URL returns GeoJSON
                            if (!newMapClickInfo[product]) {
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }

                            if(olLayerName !== "military_boundaries" && olLayerName !== "tc_ww_breakpoints" && olLayerName !== "ss_ww_communication_points") {
                                let layerCoords = mapClickCoords.x.toString() + "," + mapClickCoords.y.toString();
                                const featureServerUrl = "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/FeatureServer/"
                                let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=true&f=geojson&outSR=102100&outFields=*&geometry=" + layerCoords;

                                newMapClickInfo[product].urls[olLayerName] = featureServerUrl + ZONE_LAYERS[olLayerName].id + queryUrl;
                            }
                            else {
                                newMapClickInfo[product].urls[olLayerName] = url;
                            }
                        } else if (product === "zone_forecasts"){
                            // Manually create one URL for each zone forecast layer. Query URL returns GeoJSON
                            if (!newMapClickInfo[product]) {
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }
                            if(olLayerState[olLayerName].on) {
                                let layerCoords = mapClickCoords.x.toString() + "," + mapClickCoords.y.toString();
                                let serverUrl = "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/FeatureServer/"
                                let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=true&f=geojson&outSR=102100&outFields=*&geometry=" + layerCoords;
                                if(ZONE_FORECAST_LAYERS[olLayerName].group === "beach") {
                                    let serverUrl = "https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/marine_beachforecast_summary/MapServer/"
                                    let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=true&f=geojson&inSR=102100&outFields=*&geometry=" + layerCoords;
                                    if(ZONE_FORECAST_LAYERS[olLayerName].id === "0") {
                                        newMapClickInfo[product].urls[olLayerName] = serverUrl + ZONE_FORECAST_LAYERS[olLayerName].id + queryUrl;
                                        const day2Layer = olLayerName.replace(/.$/, '2');
                                        newMapClickInfo[product].urls[day2Layer] = serverUrl + ZONE_FORECAST_LAYERS[day2Layer].id + queryUrl;
                                    } else {
                                        newMapClickInfo[product].urls[olLayerName] = serverUrl + ZONE_FORECAST_LAYERS[olLayerName].id + queryUrl;
                                        const day1Layer = olLayerName.replace(/.$/, '1');
                                        newMapClickInfo[product].urls[day1Layer] = serverUrl + ZONE_FORECAST_LAYERS[day1Layer].id + queryUrl;
                                    }
                                } else {
                                    newMapClickInfo[product].urls[olLayerName] = serverUrl + ZONE_FORECAST_LAYERS[olLayerName].id + queryUrl;
                                }
                            }
                        } else if (product === "surface_obs") {
                            if (!newMapClickInfo[product]) {
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }
                            // Refreshes source data on click
                            const obsStationSource = LAYERS['surface_obs'].layers['station_obs_scale6'].sources['station_obs_scale6'].sourceObj.source;
                            obsStationSource.refresh();
                            // Gets clicked feature extents to zoom to selected features
                            let newExtent = olExtent.createEmpty();
                            let totalCount = 0;
                            let obsFeatures = olMap.current.forEachFeatureAtPixel(mapClickEvent.pixel,
                                function(feature) {
                                    const extent = olExtent.createEmpty();
                                    let count = 0;
                                    for(const newFeature of feature.get('features')) {
                                        if(newFeature.getProperties()['status'] === 'active') {
                                            const featureExtent = newFeature.getGeometry().getExtent();
                                            count = count + 1;
                                            olExtent.extend(extent, featureExtent);
                                        }
                                    }
                                    return [extent, count]}, {
                                layerFilter: function(layer) {
                                    return layer.getZIndex() === OL_ZINDEXES['surface_obs']
                                }
                            });
                            let COOPSFeatures = olMap.current.forEachFeatureAtPixel(mapClickEvent.pixel,
                                function(feature) {
                                    const extent = olExtent.createEmpty();
                                    let count = 0;
                                    for(const newFeature of feature.get('features')) {
                                        const featureExtent = newFeature.getGeometry().getExtent()
                                        count = count + 1;
                                        olExtent.extend(extent, featureExtent);
                                    }
                                    if(count === 0) {
                                        return null;
                                    } else {
                                        return [extent, count];
                                    }}, {
                                    layerFilter: function(layer) {
                                    return layer.getZIndex() === OL_ZINDEXES['co_ops_stations']
                                }
                            });
                            if(obsFeatures) {
                                totalCount = totalCount + obsFeatures[1];
                            }
                            if(COOPSFeatures) {
                                totalCount = totalCount + COOPSFeatures[1];
                            }
                            if(totalCount > 1) {
                                if(obsFeatures && COOPSFeatures && totalCount > 2) {
                                    olExtent.extend(newExtent, obsFeatures[0]);
                                    olExtent.extend(newExtent, COOPSFeatures[0]);
                                } else if(obsFeatures && obsFeatures[1] > 1) {
                                    olExtent.extend(newExtent, obsFeatures[0]);
                                } else if(COOPSFeatures && COOPSFeatures[1] > 1) {
                                    olExtent.extend(newExtent, COOPSFeatures[0]);
                                }
                                olMap.current.getView().fit(newExtent, {duration: 500, padding: [50, 50, 50, 50], maxZoom: 12, nearest: true});
                            };
                        } else if (product === "convective_outlooks"){
                            if (!newMapClickInfo[product]) {
                                newMapClickInfo[product] = {urls: {}, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
                            }
                            if(olLayerState[olLayerName].on) {
                                let layerCoords = mapClickCoords.x.toString() + "," + mapClickCoords.y.toString();
                                let serverUrl = "https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/SPC_wx_outlks/MapServer/"
                                let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=true&f=geojson&outSR=102100&outFields=*&inSR=102100&geometry=" + layerCoords;
                                newMapClickInfo[product].urls[olLayerName] = serverUrl + CONVECTIVE_LAYERS[olLayerName].arc_id + queryUrl;
                            }
                        } else { // Regular update: just assign the infoUpdate object to the product/point_forecast key
                            newMapClickInfo[product] = infoUpdate;
                        }
                    } catch {
                        continue;
                    }
                }
            }
        }
        // ToDo: Add the maps current time (wutever the slider was on at the time of the click, else current time)
        // to the mapClickInfo object. This will let us display the time in the popup and not have the popup
        // re-rendering if timestate changes (also time is only available at the map and below, so need to refactor
        // to get it up to the map.)

        // Add an entry for point forecasts
        const [lon, lat] = toLonLat(mapClickEvent.coordinate);
        const url = "https://api.weather.gov/points/" + lat + "," + lon;
        newMapClickInfo["point_forecast"] = {url: url, coords: {x: mapClickCoords.x, y: mapClickCoords.y}};
        // for storing menu state while popup is on
        // may not be necessary
        //if (layerMenuOn) {
        //    setPopupPrevMenuState("layer");
        //} else if (legendMenuOn) {
        //    setPopupPrevMenuState("legend");
        //} else {
        //    setPopupPrevMenuState("none");
        //}
        setMapClickPopupOn(true);
        setLayerMenuOn(false);
        setLegendMenuOn(false);
        setInfoMenuOn(false);
        return newMapClickInfo;
    }, [mapClickCoords, mapClickEvent]);

    // Updates time (same as time slider) at map click and the info request URLs when map is clicked
    // mapClickURLs are stored in Redux state
    useEffect(() => {
        if(!mapClickEvent) return;
        updateMapClickTime(selectedTime);
        const mapClickURLs = getInfoUrls();
        dispatch(mapClickURLsUpdated(mapClickURLs));
    }, [mapClickEvent, getInfoUrls, dispatch]);

    // Resets map view to user location
    // Location state only updates when user clicks geolocate button
    useEffect(() => {
        if(!location) return;
        olMap.current.getView().setCenter(location);
        olMap.current.getView().setZoom(8);
    }, [location, refreshLocation]);

    return (
        <OLMapContext.Provider value={ olMap }>
            <OLMap
                map={olMap.current}
                mapElement={mapElement}
                zIndexes={ZINDEXES}
                center={mapCenter.current}
                zoom={mapZoom.current}
                showZoom={showZoom}
                timeValues={timeValues}
                updateSelectedTime={updateSelectedTime}
                timeStateController={timeStateController.current}
                initializedCaps={initializedCaps}
                setInitializedCaps={updateInitializedCaps}
                capHandlers={capHandlers}
                updateTimeValues={updateTimeValues}
                updateStyleInfo={updateStyleInfo}
                updateProductInfo={updateProductInfo}
                customLayerInfo={customLayerInfo}
                productToggles={productToggles}
                layerOpacities={layerOpacities}
                olLayerState={olLayerState}
                validateOlStyle={validateOlStyle}
                refreshLayers={refreshLayers}
                mapClickPopupOn={mapClickPopupOn}
            >
                <NCHeader
                    zIndexVal={ZINDEXES.header}
                />
                { window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone ?
                    null :
                    <BookmarkToggleButton
                        toggleBookmarking={toggleBookmarking}
                        bookmark={bookmark}
                    />
                }
                <ResetPositionButton
                    resetPosition={resetPosition}
                />
                <GeolocationButton
                    geolocate={geolocate}
                    geolocationError={geolocationError}
                />
                <MapMenus
                    updateLayerToggles={updateProductToggles}
                    layerToggles={productToggles}
                    prevBasemap={prevBasemap}
                    selectedBasemap={selectedBasemap}
                    updateBasemap={setBasemap}
                    initializedCaps = {initializedCaps}
                    styleInfo={styleInfo}
                    zIndexVal={ZINDEXES.layer_menu}
                    layerOpacities={layerOpacities}
                    updateLayerOpacities={updateLayerOpacities}
                    mapClickPopupOn={mapClickPopupOn}
                    updateMapClickPopup={updateMapClickPopup}
                    updateCustomLayerInfo={updateCustomLayerInfo}
                    customLayerInfo={customLayerInfo}
                    setLayerMenuOn={setLayerMenuOn}
                    layerMenuOn={layerMenuOn}
                    setLegendMenuOn={setLegendMenuOn}
                    legendMenuOn={legendMenuOn}
                    setInfoMenuOn={setInfoMenuOn}
                    infoMenuOn={infoMenuOn}
                    olLayerState={olLayerState}
                    updateOlLayerState={updateOlLayerState}
                    productInfo={productInfo}
                    bookmark={bookmark}
                />
                { mapClickPopupOn ?
                    <MapClickPopup
                        map={olMap.current}
                        zIndexVal={ZINDEXES.map_click_popup}
                        updateMapClickPopup={updateMapClickPopup}
                        mapClickTime={mapClickTime}
                        olLayerState={olLayerState}
                        styleInfo={styleInfo}
                        setLayerMenuOn={setLayerMenuOn}
                        //popupPrevMenuState={popupPrevMenuState}
                        productToggles={productToggles}
                    />
                    : null
                }
                <NCFooter zIndexVal={ZINDEXES.footer} />
            </OLMap>
        </OLMapContext.Provider>
    );
}

export default NCMapViewerApp;

// initialization functions

// olLayerState:
// initial layer state attempts to read from URL first
// any product name in the URL seach parameters is considered 'on'
// if product has layers in its parameters, these are turned on
// all layers not in the product's URL parameters are turned off
// if a product is not in URL, its toggle is off, but it receives default layer and source settings from config
// input URL parameters are checked against config; invalid parameters will not be read and default layer settings will be used
// Object which maps all OL layer names to an object containing on, currentSource, layersParam, stylesParam
// Keys of olLayerState correspond the union of all OpenLayers layer names within LAYERS object within config.js
// (Within LAYERS each product has one or more layer names found in LAYERS[<product>].layers)
//
// on:            holds true/false to display the layer or not
// currentSource: holds a string containing the name of the current OpenLayers source to be used by the layer
// layersParam:   holds the value to be used within the OpenLayers Source object's 'layers' parameter
// stylesParam:   holds the value to be used within the OpenLayers Source object's 'styles' parameter
// defaultStyles: default 'styles' from config - allows olLayerState to revert to the default style if invalid styles are listed in the URL
//
// ToDo: Consider adding 'protocol' to each layer in config.js indicating 'WMS' or 'WMTS' and creating a WMTSSource react component (and store protocol in olLayerState for each layer)
// That would provide a structured way of answering the question of whether or not this is
// a WMS or WMTS source. We need to know to initialize this object since we are looking into
// the OL source object and need to know what key to use/how to look. But rather than redo
// config over that one question, I am using this temporary check below. Also We have the same issue
// when accessing source objects within source.js. We could use the protocol flag to decide
// whether to make a wms or wmts source component. But for now there is a similar workaround in
// source.js to what we have below
//
// NOTE: layersParam currently can be list or string (list for need of dynamicLayersList behavior)
// It will initialize to whatever type was used in config.js (most happen to be strings) We may want
// to keep this behavior to help differentiate between layers that need dynamic layer lists and those
// that always specify a single layer in layers param. Or make everything conform to lists and treat
// everything the same (at the cost of extra source renders to change layersParam for some products)
function initialLayerState() {
    let initLayerState = {};
    let url = new URL(DOMPurify.sanitize(window.location.href));
    let productsWithLayersInURL = [];
    if(Array.from(url.searchParams.keys()).length > 0) {
        for(const param of url.searchParams) {
            const product = param[0];
            let layerParams = [];
            // validates products & ignores other search parameters (x, y, z, basemap, etc.)
            if(product in LAYERS) {
                let subParams = param[1].split(',');
                if(subParams && subParams.length > 0) {
                    for(const subParam of subParams) {
                        // gets sub-parameters (ly=layer, so=source, st=style, lp=layer parameter)
                        if(['ly','so','st','lp'].includes(subParam.substring(0,2))) {
                            layerParams.push(subParam);
                        }
                    }
                    if(layerParams.length > 0) {
                        let urlLayers = {};
                        // id ensures sub-parameters are applied together
                        let id = '0';
                        let layerNames = layerParams.filter((layer) => { return layer.startsWith('ly') });
                        // populates urlLayers with product info
                        for(const layerName of layerNames) {
                            let layer = layerName.split('=')[0];
                            let layerEntry = layerName.split('=')[1];
                            id = layer.slice(-1);
                            // validates layer name
                            if(Object.keys(LAYERS[product].layers).includes(layerEntry)) {
                                // if a layer name has been validated and product is in this list, don't apply default settings later
                                if(!productsWithLayersInURL.includes(product)) {
                                    productsWithLayersInURL.push(product);
                                }
                                Object.assign(urlLayers, {[id] : {'layer': layerEntry}});
                            }
                        }
                        for(const paramString of layerParams) {
                            let layerParamKey = paramString.split('=')[0];
                            id = layerParamKey.slice(-1);
                            // validate id
                            if(!Number.isNaN(Number(id))) {
                                let layerParamValue = paramString.split('=')[1];
                                let layerEntry = urlLayers[id];
                                if(urlLayers[id] && urlLayers[id].layer) {
                                    let defaultSource = LAYERS[product].layers[urlLayers[id].layer].defaultSource;
                                    if(paramString.startsWith('so')) {
                                        // validate source
                                        if(Object.keys(LAYERS[product].layers[urlLayers[id].layer].sources).includes(layerParamValue)) {
                                            Object.assign(layerEntry, {'source': layerParamValue});
                                        }
                                    } else if(paramString.startsWith('st')) {
                                        // gathers style entries; these are validated later when capabilities are set
                                        Object.assign(layerEntry, {'styles': layerParamValue.split('|')});
                                    } else if(paramString.startsWith('lp')) {
                                        // validate layer parameters
                                        // currently only needed for tropical_cyclones and mrms_qpe
                                        if(product === 'tropical_cyclones') {
                                            let verifiedLayers = [];
                                            if(Array.isArray(layerParamValue.split('|'))) {
                                                for(const layerParam of layerParamValue.split('|')) {
                                                    if(LAYERS[product].styleLayerNames.includes(layerParam)) {
                                                        verifiedLayers.push(layerParam);
                                                    }
                                                }
                                                if(verifiedLayers.length === 0) {
                                                    verifiedLayers = LAYERS[product].layers[urlLayers[id].layer].sources[defaultSource].sourceObj.params_.LAYERS;
                                                }
                                                Object.assign(layerEntry, {'layerParams': verifiedLayers});
                                            }
                                        } else if(product === 'mrms_qpe') {
                                            const layerNameArray = layerParamValue.split(':');
                                            if(layerNameArray.length === 3 && layerNameArray[0] === 'mrms_qpe' &&
                                                Object.keys(MRMS_QPE_RASTER_FUNCS_TO_SUBSETS).includes(layerNameArray[1]) &&
                                                layerNameArray[2] === 'unknown@gpml') {
                                                Object.assign(layerEntry, {'layerParams': layerParamValue});
                                            }
                                        } else {
                                            if(layerParamValue === LAYERS[product].layers[urlLayers[id].layer].sources[defaultSource].sourceObj.params_.LAYERS) {
                                                Object.assign(layerEntry, {'layerParams': layerParamValue});
                                            }
                                        }
                                    }
                                    Object.assign(urlLayers, layerEntry);
                                }
                            }
                        }

                        // sets parameters to apply to olLayerState
                        // if a necessary parameter is not present, it is set from config
                        for(const olLayer in LAYERS[product].layers) {
                            let source = "";
                            let sourceLayersParam = null;
                            let sourceStylesParam = null;
                            let defaultStyles = [];
                            let layerOn = false;
                            let curDetails = {};
                            for(const [id, entry] of Object.entries(urlLayers)) {
                                if(entry.layer === olLayer) {
                                    layerOn = true;
                                    if(entry.source) {
                                        source = entry.source;
                                    }
                                    if(entry.styles) {
                                        sourceStylesParam = entry.styles;
                                    }
                                    if(entry.layerParams) {
                                        sourceLayersParam = entry.layerParams;
                                    }
                                }
                            }

                            if(source === "") {
                                source = LAYERS[product].layers[olLayer].defaultSource;
                            }
                            if(!sourceStylesParam) {
                                sourceStylesParam = LAYERS[product].layers[olLayer].sources[source].sourceObj.style_;
                            }
                            if(!sourceLayersParam && product !== 'surface_obs') {
                                sourceLayersParam = LAYERS[product].layers[olLayer].sources[source].sourceObj.params_.LAYERS;
                            }

                            if(!sourceStylesParam && product !== 'surface_obs') {
                                sourceStylesParam = LAYERS[product].layers[olLayer].sources[source].sourceObj.params_.STYLES;
                            }

                            if(product !== 'surface_obs') {
                                if(LAYERS[product].layers[olLayer].sources[source].sourceObj.style_) {
                                    defaultStyles = LAYERS[product].layers[olLayer].sources[source].sourceObj.style_;
                                } else if(LAYERS[product].layers[olLayer].sources[source].sourceObj.params_.STYLES) {
                                    defaultStyles = LAYERS[product].layers[olLayer].sources[source].sourceObj.params_.STYLES;
                                }
                            }

                            curDetails = {
                                'on': layerOn,
                                'layersParam': (sourceLayersParam) ? sourceLayersParam : [],
                                'stylesParam': (sourceStylesParam) ? sourceStylesParam : [],
                                'currentSource': source,
                                'defaultStyles': defaultStyles
                            }
                            Object.assign(initLayerState, {[olLayer]: curDetails});
                        }
                    }
                }
            }
        }
    }

    for(const product in LAYERS) {
        // sets default layer state for layers of products that are turned off
        if(!productsWithLayersInURL.includes(product)) {
            for(const olLayer in LAYERS[product].layers) {
                const defaultSource = LAYERS[product].layers[olLayer].defaultSource;
                let sourceLayersParam = null;
                let sourceStylesParam = LAYERS[product].layers[olLayer].sources[defaultSource].sourceObj.style_;

                if (sourceStylesParam || product === 'surface_obs') {
                    // This is not a WMS source object
                } else {
                    // This is a WMS source object
                    sourceLayersParam = LAYERS[product].layers[olLayer].sources[defaultSource].sourceObj.params_.LAYERS;
                    sourceStylesParam = LAYERS[product].layers[olLayer].sources[defaultSource].sourceObj.params_.STYLES;
                }

                let curDetails = {
                    'on': ('initialState' in LAYERS[product].layers[olLayer]) ? LAYERS[product].layers[olLayer].initialState : LAYERS[product].initialState, //YOU MUST ADD initialState TO config.js FOR EACH LAYER (next to defaultSource)
                    'layersParam': (sourceLayersParam) ? sourceLayersParam : [],
                    'stylesParam': (sourceStylesParam) ? sourceStylesParam : [],
                    'currentSource': defaultSource,
                    'defaultStyles': (sourceStylesParam) ? sourceStylesParam : []
                };
                Object.assign(initLayerState, {[olLayer]: curDetails});
            }
        } else if(product === "nbs") {
            //ensure hillshade & bathymetry layers are always on 
            for(const olLayer in LAYERS[product].layers) {
                if(!initLayerState[olLayer].on && LAYERS[product].layers[olLayer].initialState) {
                    const source = olLayer; //for nbs, layer and source name are the same
                    let defaultStyles = LAYERS[product].layers[olLayer].sources[source].sourceObj.style_;
                    if(!defaultStyles) {
                        defaultStyles = LAYERS[product].layers[olLayer].sources[source].sourceObj.params_.STYLES;
                    }
                    const defaultLayers = LAYERS[product].layers[olLayer].sources[source].sourceObj.params_.LAYERS;
                    Object.assign(initLayerState, {[olLayer]: {
                        'on': true,
                        'layersParam': defaultLayers ? defaultLayers : [],
                        'stylesParam': defaultStyles ? defaultStyles : [],
                        'currentSource': source,
                        'defaultStyles': defaultStyles ? defaultStyles : []
                    }});
                }
            }
        } else if(product === "surface_obs") {
            //if cached_stations or static_clusters are on, other station obs layers are also on
            let surfaceObsOn = false;
            for(const obsLayer in LAYERS[product].layers) {
                if(initLayerState[obsLayer].on && obsLayer !== 'co_ops_stations' && obsLayer !== 'station_obs_scale6') {
                    surfaceObsOn = true;
                    break;
                }
            }
            if(surfaceObsOn) {
                for(const obsLayer in LAYERS[product].layers) {
                    if(!initLayerState[obsLayer].on && obsLayer !== 'co_ops_stations') {
                        Object.assign(initLayerState, {[obsLayer]: {
                            'on': true,
                            'layersParam': [],
                            'stylesParam': [],
                            'currentSource': obsLayer,
                            'defaultStyles': []
                        }});
                    }
                }
            }

        } else if(product === "tropical_cyclones") {
            if(initLayerState['tropical_cyclones'].on) {
                const layerParams = initLayerState['tropical_cyclones'].layersParam;
                if(layerParams.includes('tropical_cyclone_track_forecast') && !layerParams.includes('tropical_cyclone_intensity_forecast')) {
                    layerParams.push('tropical_cyclone_intensity_forecast');
                } else if(layerParams.includes('tropical_cyclone_intensity_forecast') && !layerParams.includes('tropical_cyclone_track_forecast')) {
                    layerParams.push('tropical_cyclone_track_forecast');
                }
                Object.assign(initLayerState, {'tropical_cyclones' : {
                    'on': true,
                    'layersParam': layerParams,
                    'stylesParam': initLayerState['tropical_cyclones'].stylesParam,
                    'currentSource': 'tropical_cyclones',
                    'defaultStyles': initLayerState['tropical_cyclones'].defaultStyles
                }});
            }
        }
    }
    return initLayerState;
}

// initial product toggles attempts to read from URL first
// if a search param is a valid layer, it is turned on
// if a layer is not included in the search params, it is turned off
// with no URL search parameters, products are toggled according to config
// state of all products - true/false for on/off
// In the menu, products each have their own menu component and a switch that can toggle
// all layers for the product on/off
function initialProductToggles() {
    let initProductToggles = {};
    let url = new URL(DOMPurify.sanitize(window.location.href));
    if(Array.from(url.searchParams.keys()).length > 0) {
        for(const param of url.searchParams) {
            if(param[0] in LAYERS) {
                if(param[1] !=='off') {
                    Object.assign(initProductToggles, {[param[0]]: true});
                }
            }
        }
        for(const product in LAYERS) {
            if(!url.searchParams.has(product)) {
                Object.assign(initProductToggles, {[product]: false});
            }
        }
    } else {
        for (const product in LAYERS) {
            Object.assign(initProductToggles, {[product]: LAYERS[product].initialState});
        }
    }
    return initProductToggles;
}

// Initialization status of datasets that must wait on Get Capabilities requests to be enabled
function initialCaps() {
    let curInitializedCaps = {};
    for (const product in LAYERS) {
        if (LAYERS[product].capUrls) {
            Object.assign(curInitializedCaps, {[product]: (!LAYERS[product].animated)});
        }
    }
    return (curInitializedCaps);
}

// Store all known legend/style info to help display legends
// (keys should be a subset of layerToggle's keys)
// (sub keys correspond to the layer name in capabilities that style/legend info belongs to)
// styleInfo has the following format
//{ <product/layer name> :
//    { <OL layer name> :
//        [
//            { name: <name of style parsed from capabilities>,
//              title: <value parsed from capabilities - used as label in viewer>,
//              width: <width (int) parsed from capabilities>,
//              height: <height (int) parsed from capabilities>,
//              format: <format (ex img/png) parsed from capabilities>,
//              url: <url of legend image parsed from capabilities>,
//            }, ...
//        ]
//    }, ...
//}
function initialStyleInfo() {
    let curStyleInfo = {};
    for (const product in LAYERS) {
        if (LAYERS[product].capUrl) { // Invariant? If it has capUrl it will have style info
            Object.assign(curStyleInfo, {[product]: null});
        }
    }
    return (curStyleInfo);
}

// finds initial product opacities from URL or default from config
function initialLayerOpacities() {
    let curOpacities = {};
    let url = new URL(DOMPurify.sanitize(window.location.href));
    let opacityLayers = [];
    if(Array.from(url.searchParams.keys()).length > 0) {
        for(const param of url.searchParams) {
            if(param[0] in LAYERS) {
                let subParams = param[1].split(',');
                if(subParams && subParams.length > 0) {
                    for(const subParam of subParams) {
                        if(subParam.startsWith('op')) {
                            let opacity = parseInt(subParam.split('=')[1]);
                            // validates opacity
                            if(opacity && opacity >= 0 && opacity <= 100) {
                                opacityLayers.push(param[0]);
                                Object.assign(curOpacities, {[param[0]]: Number(opacity)});
                            }
                        }
                    }
                }
            }
        }
    }
    for (const product in LAYERS) {
        if(!opacityLayers.includes(product)) {
            Object.assign(curOpacities, {[product]: LAYERS[product].opacity});
        }
    }
    return (curOpacities);
}

// Store general info/metadata about products that must be parsed from capabilities
// Initialize with keywords from config.js. Has form: {[product]: {keywords: [], wmsCapUrls: [{url: "", title: ""}] }},...}
function initialProductInfo() {
    let curProductInfo = {};
    for (const product in LAYERS) {
        if (LAYERS[product].keywords) {
            Object.assign(curProductInfo, {[product]: {keywords: LAYERS[product].keywords}});
        }
    }
    return (curProductInfo);
}

// capabilities handlers for all WMS products from config
function initialCapHandlers() {
    const capHandlers = [];
    for (const product in LAYERS) {
        if (LAYERS[product].capEvents) {
            capHandlers.push({
                handler: new WMSCapabilitiesHandler(product, LAYERS[product].capUrls, LAYERS[product].capRequestInterval, LAYERS[product].snapThreshold, LAYERS[product].styleLayerNames),
                events: LAYERS[product].capEvents
            });
        }
    }
    return capHandlers;
}

/** getBreakPoint uses breakpoints info imported from config to identify the breakpoint used for a specific window pixel width
* Args: windowWidth - width of window in pixels
* Returns: string containing name of current breakpoint
* Requires: BREAKPOINT_VALUES must be defined globally and should contain xs, sm, md, lg, xl keys for meaningful results
**/
function getBreakPoint(windowWidth) {
    if (windowWidth < BREAKPOINT_VALUES.sm) return "xs";
    if (windowWidth < BREAKPOINT_VALUES.md) return "sm";
    if (windowWidth < BREAKPOINT_VALUES.lg) return "md";
    if (windowWidth < BREAKPOINT_VALUES.xl) return "lg";
    return "xl";
};

// if URL has any parameters, bookmarking is on
function getBookmark() {
    const url = new URL(DOMPurify.sanitize(window.location.href));
    if(Array.from(url.searchParams.keys()).length > 0) { return true; } else { return false; }
};

// finds initial map center from URL or default for given breakpoint
function getMapCenter(curBreakPoint) {
    let x = null;
    let y = null;
    let url = new URL(DOMPurify.sanitize(window.location.href));
    if(Array.from(url.searchParams.keys()).length > 0) {
        for(const param of url.searchParams) {
            // converts coordinate parameter strings to numbers
            if(param[0] === 'x') {
                x = Number(param[1]);
            }
            if(param[0] === 'y') {
                y = Number(param[1]);
            }
        }
    }
    // validates converted coordinates; if invalid, use breakpoint default center
    if(x && y && !Number.isNaN(x) && !Number.isNaN(y)) {
        return [x, y];
    } else {
        return fromLonLat(DEFAULT_MAP_POSITIONS[curBreakPoint.current].center);
    }
};

// finds initial map zoom level from URL or default for given breakpoint
function getMapZoom(curBreakPoint) {
    let z = null;
    let url = new URL(DOMPurify.sanitize(window.location.href));
    if(Array.from(url.searchParams.keys()).length > 0) {
        for(const param of url.searchParams) {
            // converts zoom parameter string to number
            if(param[0] === 'z') {
                z = Number(param[1]);
            }
        }
    }
    // validates converted zoom level; if invalid, use breakpoint default zoom
    if(z && !Number.isNaN(z)) {
        return z;
    } else {
        return DEFAULT_MAP_POSITIONS[curBreakPoint.current].zoom;
    }
};

// sets basemap at load from URL or as DEFAULT_BASEMAP
function getInitialBasemap() {
    let initialBasemap = DEFAULT_BASEMAP;
    let url = new URL(DOMPurify.sanitize(window.location.href));
    if(Array.from(url.searchParams.keys()).length > 0) {
        let otherLayersOn = false;
        // if VLM is the only layer on & a basemap isn't in the URL, set the basemap to imagery
        if(url.searchParams.has('vlm') && !url.searchParams.has('basemap')) {
            for (const product in LAYERS) {
                if(url.searchParams.has(product) && product !== 'vlm') {
                    otherLayersOn = true;
                    break;
                }
            }
            if(!otherLayersOn) {
                initialBasemap = "arcgis/imagery";
            }
        } else if(url.searchParams.has('basemap')) {
            // check if the basemap parameter is in BASEMAPS to validate
            const basemapParam = url.searchParams.get('basemap');
            if(Object.keys(BASEMAPS).includes(basemapParam)) {
                initialBasemap = BASEMAPS[basemapParam].map;
            }
        }
    }
    return initialBasemap;
};